*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
}

body{
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #dee2e6;
}

.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color:  #21222A;
    color: #DEEBF8;
    padding: 30px;
    font-weight: 700;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.navgrp{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.icon{
    width: 2em;
}
.title {
    color: #61DAFB;
}

.container{
    display: flex;
    width: 550px;
    flex-direction: column; 
    color: white;
    
    
}

.Main{
    display: flex;
    flex-direction: column;
    gap: 2em;
    justify-content: center;
    align-items: center;
    padding: 100px 50px;
    background-color: #282D35;
    background-image: url(./images/logogrey.png);
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
}

.factlist{
    display: flex;
    flex-direction: column;
    margin-top: 2em;
    gap: 2em;
    list-style: inside;
}

.factlist > li::marker{
    color: #61DAFB;
    font-size: 1.5rem;
}



.reference{
    position: relative;
}

.mode {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translate(0,-50%);
}



.switch {
    font-size: 16px;
    position: relative;
    display: inline-block;
    width: 30px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #21222A;
    border: 1px solid #282D35;
    transition: .4s;
    border-radius: 30px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    border-radius: 20px;
    left: 1px;
    bottom: 1.1px;
    background-color: white;
    transition: .4s;
}

input:checked+.slider {
    background-color: white;
    border: 1px solid #282D35;
}

input:focus+.slider {
    box-shadow: 0 0 1px #282D35;
}

input:checked+.slider:before {
    transform: translateX(10px);
    background-color: #21222A;
}